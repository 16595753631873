.mainHero {
  background: url(./image/15.jpg);
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;
  font-family: "Poppins";
  display: flex;
  justify-content: center;
}

.mainHero::before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
}

.mainHero .content {
  background: transparent;
  width: 80%;
  z-index: 2;
  animation: contentAppear 0.5s linear 1;
  position: absolute;
  bottom: 100px;
}

.mainHero h1 {
  font-size: 10rem;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 100%;
  letter-spacing: 2px;
  text-shadow: 0px 5px 50px rgba(0, 0, 0, 0.9);
}
p {
  color: #fff;
  letter-spacing: 1px;
  text-shadow: 0px 5px 50px rgba(0, 0, 0, 0.9);
}
.para1 {
  padding-bottom: 100px;
  padding-top: 50px;
}

br {
  display: none;
}

.mainHero .content .para2 {
  animation: contentAppear 1s linear 1;
  border: 1px dotted #fff;
  background: #222222b0;
}

@keyframes contentAppear {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@keyframes bgAppear {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.3);
  }
}

.para1 {
  text-align: center;
  text-transform: uppercase;
  position: relative;
}

@media screen and (max-width: 458px) {
  .mainHero h1 {
    font-size: 4rem;
    color: #fff;
    font-weight: 700;
    line-height: 100%;
  }

  .mainHero .content p {
    font-size: 14px;
    letter-spacing: 1px;
  }

  br {
    display: block;
  }
}
