.footer {
  background-color: black;
  width: 100%;
  min-height: 100px;
  position: relative;

  bottom: 0;
  margin-top: 0px;
  padding-top: 20px;
  padding-bottom: 5px;
  border-top: 10px solid #db2777;
}

.footer a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  transition: all 0.5s;
}
s .footer a:hover {
  color: #fff;
}

.footer img {
  width: 30px;
}

.social {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.sss {
  display: flex;
  justify-content: center;
}

.footermenu {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding-top: 20px;
  color: #db2777;
  text-transform: uppercase;
  font-size: 12px;
}

.copyright {
  font-size: 12px;
  padding-top: 50px;
}

.hr-footer {
  color: #db2777;
  border: 1px solid #db2777;
  width: 70%;
  margin: 10px auto;
}

/* Extra Small Devices (Phones) */
@media only screen and (max-width: 576px) {
}

/* Small Devices (Tablets) */
@media only screen and (min-width: 577px) and (max-width: 767px) {
}

/* Medium Devices (Laptops, Desktops) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
}

/* Large Devices (Large Laptops, Desktops) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

/* Extra Large Devices (Large Desktops) */
@media only screen and (min-width: 1200px) {
}
